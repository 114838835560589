import { useEffect, useRef, useState } from "react";

// material ui
import { Theme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

// material-ui icons
import PeopleIcon from "@mui/icons-material/PeopleRounded";

// Project import
import Button from "components/@extended/LoadingButton";
import { useTranslation } from "utils/locales/utilityFunctions";
import ShareDialog, { ContactType } from "./shareDialog";
import useEstatesAndMaps from "hooks/useEstatesAndMaps";
import useAuth from "hooks/useAuth";
import { EstateProps, MapProps, ProjectProps } from "types/estatesAndMap";
import getDataOptions from "components/estatesAndMapSelecter/getDataOptions";
import { DataShareItem, RoleTypes, TeamMember } from "types/auth";
import getContactOptions from "components/estatesAndMapSelecter/getContactOptions";
import IconButton from "components/@extended/IconButton";
import useAppFunctionalityConfig from "hooks/useAppFunctionalityConfig";
import { mixpanelEvents } from "config/servicesConfig/mixpanelEvents";
import mixpanel from "mixpanel-browser";

export interface ShareDataProps {
  withDataSelector?: boolean;
  withPersonSelector?: boolean;
  withButton?: boolean;
  withAudience?: boolean;
  openModal?: boolean;
  selectedPerson?: ContactType;
  resetExtOpenModal?: (state: boolean) => void;
}
const ShareData = ({
  withButton,
  withDataSelector,
  withPersonSelector,
  withAudience,
  openModal,
  selectedPerson,
  resetExtOpenModal,
}: ShareDataProps) => {
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  // --- Hooks --- //
  const { config } = useAppFunctionalityConfig();
  const { translate } = useTranslation();
  const {
    selectedEstateId,
    selectedMapId,
    selectedProjectId,
    estates,
    orgEstates,
    maps: userMaps,
    orgMaps,
    projects,
    orgProjects,
    setSelectedEstateId,
    setSelectedMapId,
    setSelectedProjectId,
  } = useEstatesAndMaps();
  const { user, userOrg, orgTeam, orgContacts, cloudFunctionError, callCloudFunction } = useAuth();

  // --- State --- //
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<null | "map" | "estate" | "project">(null);
  const [teamList, setTeamList] = useState<ContactType[] | null>(null);
  const [contactList, setContactList] = useState<ContactType[] | null>(null);

  // --- References --- //
  const dataOptions = useRef<{
    list: any[];
    uniqueEstates: string[];
    estateIdsAndNames?: { [key: string]: string };
  }>({ list: [], uniqueEstates: [], estateIdsAndNames: {} });
  const [selectedData, setSelectedData] = useState<null | MapProps | EstateProps | ProjectProps>(
    null
  );
  const estateMaps = useRef<null | MapProps[]>(null);
  const categories = useRef<string[] | undefined>();

  // --- Data --- //
  // Roles
  const ROLE_OPTIONS = [
    { id: "viewer" as RoleTypes, status: "enabled", label: translate("role-view") },
    { id: "commentator" as RoleTypes, status: "enabled", label: translate("role-comment") },
    { id: "editor" as RoleTypes, status: "enabled", label: translate("role-edit") },
    { id: "admin" as RoleTypes, status: "enabled", label: translate("role-admin") },
  ];

  // --- Effects --- //
  useEffect(() => {
    if (orgTeam && user) {
      const newList = Object.entries(orgTeam)
        .map(([id, el]: [string, TeamMember]) => {
          const name = el.name && el.name !== "" ? el.name : el.email;
          return {
            ...el,
            id: id,
            name: name,
            label: name + ":" + el.email,
            personType: "team" as any,
            personTypeLabel: translate("team"),
            personTypeLabelText: translate("team"),
          };
        })
        .filter((el) => el.id !== user.id);
      setTeamList(newList);
    }
  }, [orgTeam]);
  useEffect(() => {
    if (orgContacts && selectedData) {
      const newList = getContactOptions({
        userId: user ? user!.id! : null,
        contacts: orgContacts,
        selectedData: selectedData,
        orgMapIds: Object.values(orgMaps).map((el) => el.id),
        orgProjectIds: Object.values(orgProjects).map((el) => el.id),
        personTypeLabelText: translate("contacts"),
      });
      setContactList(newList);
    }
  }, [orgContacts, selectedData]);

  useEffect(() => {
    if (loading && cloudFunctionError) {
      setLoading(false);
    }
  }, [cloudFunctionError]);

  useEffect(() => {
    dataOptions.current = getDataOptions({
      user,
      estates,
      orgEstates,
      maps: userMaps,
      orgMaps,
      projects,
      orgProjects,
      selectedPerson,
      overviewText: translate("overview"),
      allRoleOptions: ROLE_OPTIONS,
    });
  }, [estates, userMaps, orgMaps, selectedPerson]);

  useEffect(() => {
    if (openModal && !modalOpen) {
      setModalOpen(true);
    }
  }, [openModal]);

  useEffect(() => {
    if (selectedProjectId && projects[selectedProjectId]) {
      setSelectedData(projects[selectedProjectId]);
      setType("project");
      categories.current = projects[selectedProjectId].categories;
    } else if (selectedMapId && userMaps[selectedMapId]) {
      setSelectedData(userMaps[selectedMapId]);
      setType("map");
      categories.current = userMaps[selectedMapId].categories;
    } else if (selectedEstateId && estates[selectedEstateId]) {
      setSelectedData(estates[selectedEstateId]);
      setType("estate");
      estateMaps.current = Object.values(userMaps).filter(
        (map) => map.estateId === estates[selectedEstateId].id
      );
      let cate: string[] = [];
      estateMaps.current.forEach((map) => {
        if (map.categories) {
          for (const el of map.categories) {
            if (!cate.includes(el)) cate.push(el);
          }
        }
      });
      categories.current = cate;
    } else {
      setSelectedData(null);
    }
  }, [selectedMapId, selectedProjectId, selectedEstateId, estates, projects, userMaps]);

  // --- input checking --- //
  if (!user || !userOrg) return null;

  // --- Save button handler --- //
  async function saveHandler(persons: ContactType[], data: EstateProps | MapProps | ProjectProps) {
    setLoading(true);
    // Error handling
    if (!type) return;
    // Retrieve audience data
    const audience = persons.filter((el) => el.personType === "audience");
    // Divide data into team members and contacts
    const team: ContactType[] = persons.filter((el) => el.personType && el.personType === "team");
    const existingContacts: ContactType[] = persons.filter(
      (el) => el.id && el.personType && el.personType === "contact"
    );
    const newContacts: ContactType[] = persons.filter(
      (el) => !el.id && el.personType && el.personType === "contact"
    );
    // Call backend
    let promises: Promise<any>[] = [];
    // Update audience state
    if (withAudience && selectedData) {
      const prom = callCloudFunction("setAudienceState", {
        estateId: type === "estate" ? data.id : null,
        mapIds: type === "map" ? data.id : null,
        audienceActive: audience[0].inputValue === "true",
      });
      promises.push(prom);
    }
    // Update team members
    team.forEach(async (el) => {
      const shareEl = [
        {
          inviterId: user?.id ? user?.id : "fail",
          id: data.id,
          role: el.role ? el.role : "viewer",
          categories: el.categories ? el.categories : null,
          expireDate: el.date ? el.date : null,
        },
      ];
      let estates: DataShareItem[] | null = type === "estate" ? shareEl : null;
      let maps: DataShareItem[] | null = type === "map" ? shareEl : null;
      let projects: DataShareItem[] | null = type === "project" ? shareEl : null;
      const prom = callCloudFunction("updateTeamMember", {
        id: el.id,
        orgId: userOrg?.id,
        estates,
        maps,
        projects,
      });
      promises.push(prom);
    });

    // Create new contacts
    newContacts.forEach(async (el) => {
      const shareEl = [
        {
          inviterId: user?.id ? user?.id : "fail",
          id: data.id,
          role: el.role ? el.role : "viewer",
          categories: el.categories ? el.categories : null,
          expireDate: el.date ? el.date : null,
        },
      ];
      let estates: DataShareItem[] | null = type === "estate" ? shareEl : null;
      let maps: DataShareItem[] | null = type === "map" ? shareEl : null;
      let projects: DataShareItem[] | null = type === "project" ? shareEl : null;
      const prom = callCloudFunction("createContact", {
        email: el.email,
        orgId: userOrg?.id,
        estates,
        maps,
        projects,
      });
      promises.push(prom);
    });
    // Update existing contacts
    existingContacts.forEach(async (el) => {
      const shareEl = [
        {
          inviterId: user?.id ? user?.id : "fail",
          id: data.id,
          role: el.role ? el.role : "viewer",
          categories: el.categories ? el.categories : null,
          expireDate: el.date ? el.date : null,
        },
      ];
      let estates: DataShareItem[] | null = type === "estate" ? shareEl : null;
      let maps: DataShareItem[] | null = type === "map" ? shareEl : null;
      let projects: DataShareItem[] | null = type === "project" ? shareEl : null;
      const prom = callCloudFunction("updateContact", {
        id: el.id,
        userCreated: el.status !== "invite-pending",
        orgId: userOrg?.id,
        estates,
        maps,
        projects,
      });
      promises.push(prom);
    });

    await Promise.all(promises);
    // Log event to mixpanel
    mixpanel.track(mixpanelEvents.mapOrProjectShared);
    setLoading(false);
    shareButtonHandler();
  }

  // --- Set selected data --- //
  const selectedDataChangeHandler = (data: any) => {
    if (!data) {
      setSelectedEstateId(null);
      setSelectedMapId(null);
      setSelectedProjectId(null);
      setType(null);
      return;
    }
    if (data && data.dataElType && data.dataElType === "map" && data.mapId) {
      setSelectedEstateId(null);
      setSelectedMapId(data.mapId);
      setSelectedProjectId(null);
      setType(null);
    } else if (data && data.dataElType && data.dataElType === "project" && data.projectId) {
      setSelectedEstateId(null);
      setSelectedMapId(null);
      setSelectedProjectId(data.projectId);
      setType(null);
    } else {
      setSelectedEstateId(data.estateId);
      setSelectedMapId(null);
      setSelectedProjectId(null);
      setType(null);
    }
  };

  // --- Share button --- //
  function shareButtonHandler() {
    setModalOpen(!modalOpen);
    if (resetExtOpenModal) {
      resetExtOpenModal(!openModal);
      setSelectedEstateId(null);
      setSelectedMapId(null);
      setSelectedProjectId(null);
      setType(null);
    }
  }
  // --- Return JSX --- //
  let disabledShareButton = true;
  if (config && config.layout && config.layout.shareData && config.layout.shareData === "enabled") {
    disabledShareButton = false;
  }
  return (
    <div className="share-dialog-wrapper">
      <ShareDialog
        open={modalOpen}
        user={user}
        withDataSelector={withDataSelector !== undefined ? withDataSelector : false}
        withPersonSelector={withPersonSelector !== undefined ? withPersonSelector : false}
        withAudience={withAudience !== undefined ? withAudience : false}
        loading={loading}
        selectedData={selectedData}
        dataType={type}
        dataOptions={dataOptions.current}
        allRoleOptions={ROLE_OPTIONS}
        selectedPerson={selectedPerson}
        teamList={teamList}
        contactList={contactList}
        estateMaps={estateMaps.current}
        categoriesFromData={categories.current}
        handleSave={saveHandler}
        handleClose={shareButtonHandler}
        handleChangeSelectedData={selectedDataChangeHandler}
      />

      <div className="share-dialog-button-wrapper">
        {/* Not Mobile */}
        {withButton && !matchesSm && (
          <Button
            className="share-dialog-button"
            variant="contained"
            // sx={{ bgcolor: 'primary.light', height: '36px' }}
            sx={{
              bgcolor: "primary.light",
              height: "36px",
              "&.Mui-disabled": {
                bgcolor: "secondary.lighter",
                color: "secondary.main",
              },
            }}
            startIcon={<PeopleIcon />}
            disabled={disabledShareButton || !selectedMapId || selectedProjectId !== null}
            onClick={shareButtonHandler}
          >
            {translate("map-toolbar-share-button-text")}
          </Button>
        )}

        {/* Mobile */}
        {withButton && matchesSm && (
          <IconButton
            variant="contained"
            sx={{ bgcolor: "primary.light", height: "36px" }}
            disabled={disabledShareButton || !selectedMapId}
            onClick={shareButtonHandler}
          >
            <PeopleIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default ShareData;
